const parallaxRocket = () => {
  if (document.getElementById("rocket-launch")) {
    const blue = document.getElementById("blue");
    const yellow = document.getElementById("yellow");
    const black = document.getElementById("black");
    const rocket = document.getElementById("rocket");

    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      const imgLeftValue = blue.offsetLeft;
      const imgTopValue = blue.offsetTop;

      yellow.style.left = imgLeftValue + scrollValue * 0.9 + "px";
      black.style.left = imgLeftValue + scrollValue * 0.45 + "px";
      rocket.style.left = imgLeftValue + scrollValue * 2.0 + "px";

      yellow.style.top = imgTopValue + -scrollValue * 0.3 + "px";
      black.style.top = imgTopValue + -scrollValue * 0.15 + "px";
      rocket.style.top = imgTopValue + -scrollValue * 0.6 + "px";
    });
  }
};

export { parallaxRocket };
